// Single content
h1.page-title {
    position: relative;
    display: block;
    font-size: 50px;
    font-weight: bold;
    color: $bprimary;
    text-transform: uppercase;
    text-align: center;
    margin: 1.5em 0;
    &:after {
        content: "";
        width: 10%;
        height: 1px;
        background: $bprimary;
        position: absolute;
        left: 45%;
        bottom: -.5em;
    }
}

.page-body {
    max-width: 880px;
    margin: 0 auto;
    display: block;
    color: $bblack;
    font-size: 18px;
    font-weight: $fwr;
    font-family: $bfaf;
    line-height: 2em;

    @import "typography";
}

// Collections
.link-gallery {
    display: block;
    margin: 2em 0;

    .gallery-title {
        display: block;
        font-size: 1.6em;
        color: $bprimary;
        margin-bottom: .5em;
    }

    ul {
        display: block;
        margin-left: 2em;

        li {
            font-size: 1em;
            list-style: none;
            padding: 7px 0;
            &:before {
                margin-left: -1em;
                margin-right: .5em;
                content: '\f105';
                color: $bprimary;
                font-family: 'FontAwesome';
            }

            a {
                font-size: 1.1em;
                font-weight: bold;
                color: $bblack;
                &:hover { text-decoration: underline; }
            }
        }
    }
}

// Listing nodes
.node-list {
    width: 100%;
    display: block;

    .node-item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;
        margin: 1.5em 0;
        border-bottom: 2px dotted $glight;
        &:last-of-type { border-bottom: none; }

        .img-container {
            width: 150px;
            display: inline-block;
            margin-right: 1.5em;
            img { width: 100%; height: auto; display: block; }
        }

        .node-content {
            flex: 1;

            h2 {
                display: block;
                margin-bottom: 1em;
                a {
                    font-size: 1.3em;
                    font-weight: bold;
                    color: $bprimary;
                    &:hover { text-decoration: underline; }
                }
            }

            .page-body {
                display: block;
                margin-bottom: .75em;
            }

            .btn { display: inline-block; }
        }
    }
}

// References teaser
.references-teaser {
    width: 100%;
    display: block;
    background: linear-gradient($bwhite, $glight);
    padding-bottom: 2em;
    margin: 5em 0 1em 0;

    .references-teaser-title {
        position: relative;
        display: block;
        font-size: 32px;
        font-weight: bold;
        color: $bprimary;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1.5em;
        &:after {
            content: "";
            width: 30%;
            height: 1px;
            background: $bprimary;
            position: absolute;
            left: 35%;
            bottom: -.5em;
        }
    }

    .references-line {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        img {
            display: inline-block;
            width: 300px;
            margin: 0;
        }
    }

    .btn {
        display: inline-block;
        position: relative;
        top: 3.35em;
    }
}

// References grid
.references-gallery {
    width: 100%;
    display: block;
    margin: 2em 0;

    .wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .reference-item {
        width: 22%;
        display: inline-block;
        margin: 1.5%;

        img {
            width: 100%;
            border-radius: 20px;
        }
    }
}