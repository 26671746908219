// Alerts
.alert {
    width: 100%;
    display: block;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: bold;
    color: $bblack;
    margin: 1.2em 0;
    padding: .5em 2%;

    &.alert-success { color: $bsuccess !important; }
    &.alert-danger { color: $bdanger !important; }
    &.alert-warning { color: $bwarning !important; }

    &.alert-success-border { border: 3px solid $bsuccess !important; }
    &.alert-danger-border { border: 3px solid $bdanger !important; }
    &.alert-warning-border { border: 3px solid $bwarning !important; }
}

// Buttons
.btn {
    display: inline-block;
    font-family: $bfaf;
    font-size: 1em;
    font-weight: bold;
    line-height: 1em;
    background: none;
    border: none;
    border-radius: 2px;
    padding: .85em 1.25em;
    color: $bprimary;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    outline: none;
    @include transition(color, 0.15s, ease-in-out);
    @include transition(border-color, 0.15s, ease-in-out);
    @include transition(background-color, 0.15s, ease-in-out);

    &.btn-default {
        color: $bblack;
        font-weight: $fwb;
        font-family: $bfaf;
        background: $gbase;
        &:hover {
            background: darken($gbase, 5%);
        }
    }

    &.btn-white {
        color: $bprimary;
        font-weight: $fwb;
        font-family: $bfaf;
        background: $bwhite;
        border: 1px solid lighten($gray, 50%);
        &:hover {
            background: $glighter;
        }
    }

    &.btn-primary {
        color: $bwhite;
        font-family: $bfaf;
        background: $bprimary-dark;
        &:hover {
            background: lighten($bprimary-dark, 5%);
        }
    }
}

.form-input {
    height: 2em;
    font-family: $bfaf;
    font-size: 1em;
    font-weight: normal;
    padding: 0 .5em;
    outline: none;
    border-radius: 2px;
    border: none;
    border: 1px solid $glighter;
    background: $bwhite;
    @include single-box-shadow(1px, 1px, 3px, 0px, lighten($shadow, 10%));
    &:hover { background: darken($bwhite, 2%); }
    &:focus {
        border: 1px solid darken($glighter, 25%);
        background: $bwhite;
    }
    &:disabled {
        cursor: not-allowed;
        border: 1px solid $glighter;
        background: $bwhite;
    }
}

.form-select {
    height: 2.5em;
    font-family: $bfaf;
    font-size: 1em;
    border: 1px solid $glight;
    border-radius: 2px;
    padding: 0;
    outline: none;
}

label.has-success {
    color: $bsuccess;
    span { color: $bsuccess !important; }
    .form-input { color: $bsuccess !important; border: 1px solid $bsuccess !important; }
    select { border: 1px solid $bsuccess !important; }
}

label.has-warning {
    color: $bwarning;
    span { color: $bwarning !important; }
    .form-input { color: $bwarning !important; border: 1px solid $bwarning !important; }
    select { border: 1px solid $bwarning !important; }
}

label.has-error {
    color: $bdanger;
    span { color: $bdanger !important; }
    .form-input { color: $bdanger !important; border: 1px solid $bdanger !important; }
    select { border: 1px solid $bdanger !important; }
}
