div, p {
    color: $bblack;
    font-size: 18px;
    font-weight: $fwr;
    line-height: 2em;
    margin-bottom: 1.5em;
}

h2, h3, h4, h5, h6 {
    color: $bblack;
    font-family: $bfaf;
    font-weight: $fwb;
    line-height: 2em;
    margin-top: .7em;
    margin-bottom: 1em;
    a {
        text-decoration: none;
        &:hover { text-decoration: underline; }
    }
}

h2 { font-size: 26px; }
h3 { font-size: 22px; }
h4 { font-size: 19px; }
h5 { font-size: 18px; }
h6 { font-size: 18px; }

ul, ol {
    width: 96%;
    margin: .8em 0 1.2em 4%;
}

ul li, ol li {
    list-style-type: disc;
    font-weight: $fwr;
}

ol li { list-style-type: decimal; }
ul li {
    list-style: none;
    padding: .35em 0;
    &:before {
        margin-left: -1em;
        margin-right: .5em;
        content: '\f105';
        color: $bblack;
        font-family: $ifaf;
    }
}

a {
    color: $bblack;
    text-decoration: underline;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 1.5em 0;

    th, td {
        padding: .5em;
        vertical-align: middle;

        p { font-size: 1.2em; color: $bprimary; margin-bottom: 0; }
        a { color: $bprimary; }
        i.fa { color: $gray; }
    }
}

.stressed-text {
    color: $bblack;
}

figure.image {
    position: relative;
    max-width: 100%;
    display: block;
    margin: .5em auto;
    &.video-embed {
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        iframe {
            position: absolute; top: 0; left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &.open-gallery {
        &:after {
            content: '\f00e';
            position: absolute; right: 20px; top: 20px;
            z-index: 400;
            font-family: $icon-font-family;
            font-size: 32px;
            color: $bwhite;
            text-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
        }
    }
    img {
        max-width: 100%;
        position: relative;
        z-index: 200;
        height: auto;
        display: block;
    }
    figcaption {
        margin: 0;
        padding: 7px;
        background: $glightest;
        font-size: .85em;
        text-align: center;
    }
}