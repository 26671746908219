@media only screen and (max-width: 1300px) {

    .wrapper {
        width: 1100px;
    }

    .herospace {
        .featured-welcome {
            h1 { font-size: 32px; }
            h2 { font-size: 18px; margin-top: .5em; }
        }
    }

    h1.page-title {
        font-size: 42px;
        margin: 1em 0 2.5em 0;
    }

    .site-footer {
        .footer-logo {
            height: 300px;
        }

        .footer-links {
            .nav-menu {
                li {
                    margin: 0 1em;
                }
            }
        }
    }

}

@media only screen and (max-width: 1060px) {

    body {
        padding-top: 0;
    }

    .wrapper {
        width: 96%;
        padding: 0 2%;
    }

    .site-header {
        position: static;

        .logo {
            width: 260px;
            height: 66px;
            margin-top: 10px;
        }

        .nav-menu {
            margin-top: 1.25em;

            li {
                margin-left: 1em;
                a { padding: .5em 0; }
            }
        }
    }

    .herospace {
        .wrapper { padding-top: 50px; }
    }

}

@media only screen and (max-width: 960px) {

    .site-header {
        .logo {
            width: 220px;
            height: 56px;
            margin-top: 17px;
        }

        .nav-menu {
            li {
                a { font-size: .85em; }
            }
        }
    }

    .herospace {
        height: 620px;

        .featured-welcome {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        .featured-services {
            h2.services-title {
                font-size: 22px;
            }

            .service {
                h3 { font-size: 1em; }
            }
        }
    }

    .references-teaser {
        .references-line {
            height: 180px;
            img { width: 180px; height: 180px; }
        }
    }

    .contact-section {
        padding-bottom: 0;

        .contact-body {
            padding: 2em 0;

            h2 { font-size: 24px; }
        }
    }

    .site-footer {
        .footer-logo {
            height: 260px;
        }

        .footer-links {
            .nav-menu {
                li {
                    margin: 0 .75em;
                    a { font-size: .85em; }
                }
            }
        }
    }

}

@media only screen and (max-width: 840px) {

    .site-header {
        &.open-menu {
            height: auto;
            .wrapper { display: block; }
        }

        .nav-menu {
            .nav-trigger {
                display: block;
            }

            ul.main-list {
                display: none;
                width: 100%;
                text-align: left;
                li {
                    display: block;
                    &:last-child a { border: none; }
                    a {
                        padding: 1em;
                        font-size: 1.2em;
                        line-height: 1.3em;
                        display: block;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 660px) {

    .site-header {
        .logo {
            width: 190px;
            height: 48px;
            margin-top: 23px;
        }

        .nav-menu {
            .nav-trigger { font-size: 1.1em; }
        }
    }

    .herospace {
        background-size: 800%;
        height: auto;

        .wrapper {
            padding-top: .5em;
        }

        .featured-welcome {
            padding: .75em 0;
            &:after, &:before { display: none; }

            h1 { font-size: 20px; }
            h2 { font-size: 17px; line-height: 1.5em; }
        }

        .featured-services {
            margin-top: 1em;
            margin-bottom: 1em;

            h2.services-title {
                padding-left: 1em;
                padding-right: 1em;
            }

            .services-wrapper {
                flex-wrap: wrap;
            }

            .service {
                width: 100%;
                border: none;
                margin: 1em 0;
                &:nth-of-type(2) { border: none; }
            }
        }
    }

    h1.page-title {
        font-size: 30px;
        line-height: 1.5em;
        margin-bottom: 1.5em;
    }

    .page-body {
        table {
            width: 100%;
            td {
                width: 100% !important;
                padding-bottom: 1em;
                display: block;
            }
        }
    }

    .references-teaser {
        .references-line {
            height: 70px;
            img { width: 70px; height: 70px; }
        }
    }

    .references-gallery {
        .reference-item {
            width: 47%;
        }
    }

    .site-footer {
        .footer-links {
            .nav-menu {
                li { display: block; line-height: 2.2em; text-align: left; }
            }
        }
    }

}