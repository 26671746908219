// Herospace
.herospace {
    width: 100%;
    height: 700px;
    display: block;
    margin-top: -3em;
    background: url(../img/herospace-bg.jpg) no-repeat;

    .wrapper {
        text-align: center;
        padding-top: 70px;
    }

    .featured-welcome {
        width: 80%;
        position: relative;
        display: block;
        margin: 0 auto;
        padding: 3em 2em;
        color: $bwhite;
        background: rgba($bprimary-alt, .6);
        border-radius: 6px;
        &:after, &:before {
            content: "";
            width: 40%;
            height: 1px;
            background: $bwhite;
            position: absolute;
            left: 30%;
        }
        &:after { bottom: 1.5em; }
        &:before { top: 1.5em; }

        h1 {
            font-size: 40px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.3em;
            text-shadow: 2px 4px rgba($bblack, .5);
        }

        h2 {
            font-size: 20px;
            font-weight: normal;
            text-shadow: 1px 1px rgba($bblack, .15);
        }
    }

    .featured-services {
        width: 100%;
        display: block;
        margin-top: 3.5em;
        background: rgba($bprimary, .8);
        border-radius: 6px;
        color: $bwhite;

        h2.services-title {
            display: block;
            padding: 1.2em 0;
            background: rgba($bprimary-alt, .6);
            border-radius: 6px 6px 0 0;
            font-size: 26px;
            font-weight: bold;
            line-height: 1.3em;
            text-transform: uppercase;
        }

        .services-wrapper {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 1.5em 0;
            padding: 0 3em;
        }

        .service {
            width: 24%;
            display: inline-block;
            text-align: center;
            &:nth-of-type(2) {
                padding: 0 3em;
                border-left: 1px solid $bprimary-accent;
                border-right: 1px solid $bprimary-accent;
            }

            .service-icon {
                max-width: 60px;
                display: inline-block;
                margin-bottom: 1em;
                img { width: 100%; }
            }

            h3 {
                font-size: 1.2em;
                font-weight: normal;
                line-height: 1.5em;
                b { display: block; }
            }
        }

        .btn {
            position: relative;
            top: .25em;
            display: inline-block;
            margin: 1em auto -1em auto;
        }
    }
}

// Contact information
.contact-section {
    width: 100%;
    display: block;
    padding-bottom: 2em;
    margin: 5em 0 0 0;

    .contact-title {
        position: relative;
        display: block;
        font-size: 32px;
        font-weight: bold;
        color: $bprimary;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1.5em;
        &:after {
            content: "";
            width: 30%;
            height: 1px;
            background: $bprimary;
            position: absolute;
            left: 35%;
            bottom: -.5em;
        }
    }

    .contact-body {
        width: 100%;
        display: block;
        background: $bprimary;
        padding: 3em 0;
        color: $bwhite;
        text-align: center;

        h2 {
            font-size: 28px;
            font-weight: bold;
            line-height: 2em;
            margin-bottom: 1em;
        }

        p {
            font-size: 1.2em;
            line-height: 2.2em;
            margin: .5em 0;
            i.fa { display: block; font-size: 2em; }
        }
    }

    .btn {
        display: inline-block;
        position: relative;
        top: -1.45em;
    }
}