.site-header {
    width: 100%;
    height: 120px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: block;
    background: linear-gradient($bwhite, $glight);

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: .9em;
    }

    .logo {
        width: 326px;
        height: 83px;
        display: inline-block;
        background: url(../img/platform-logo-full@2x.png) no-repeat;
        background-size: 100%;
    }

    .nav-menu {
        margin-top: .75em;
        text-align: right;

        .nav-trigger {
            position: relative;
            z-index: 200;
            font-size: 1.4em;
            font-weight: bold;
            color: $bprimary;
            text-transform: uppercase;
            display: none;
        }

        li {
            display: inline-block;
            margin-left: 1.75em;

            a {
                display: inline-block;
                color: $bblack;
                font-size: .9em;
                font-weight: bold;
                text-transform: uppercase;
                padding: 1em .25em;
                border-bottom: 1px solid $bblack;
                &:hover {
                    color: lighten($bblack, 10%);
                    border-color: lighten($bblack, 10%);
                }
            }

            &.active {
                a { color: $bprimary; border-color: $bprimary; }
            }
        }
    }
}

.site-container {
    display: block;
    margin: 3em 0;
}
