@import "./vendor/bower_components/font-awesome/scss/font-awesome";
@import "./vendor/bower_components/compass-sass-mixins/lib/compass";
@import "reset";
@import "variables";
@import "lightbox";
@import "mixins";
@import "ui";

@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=latin,latin-ext);

body {
    background: $glightest;
    font-family: $base-font-family;
    font-size: $base-font-sizepx;
    color: $tc-base;
    background: $body-bgc;
    text-align: center;
    overflow-y: scroll;
    padding-top: 120px;
}

.wrapper {
    width: $base-wrapper;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

@import "partials/header";
@import "partials/footer";

@import "pages/home";
@import "pages/single";
@import "pages/page";

@import "mediaqueries";