.site-footer {
    width: 100%;
    display: block;

    .footer-logo {
        width: 100%;
        height: 425px;
        display: flex;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        background: url(../img/footer-swirl.png) top center no-repeat;
        background-size: 100%;

        .logo-badge {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            background: $bwhite;
            border: 1px solid $glight;
            border-radius: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                max-width: 80%;
                height: auto;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .footer-links {
        width: 100%;
        display: block;
        background: linear-gradient($bprimary, $bprimary-dark);
        padding: 1em 0;

        .nav-menu {
            display: block;
            text-align: center;

            li {
                display: inline-block;
                margin: 0 2em;

                a {
                    display: inline-block;
                    font-weight: normal;
                    color: $bwhite;
                    text-transform: uppercase;
                    &:hover { text-decoration: underline; }
                }
            }
        }
    }
}