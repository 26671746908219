/////////////////////////////////////////
// Declare project variables           //
/////////////////////////////////////////

// Base variables
$base-wrapper:              1160px;
$body-bgc:                  #f7f7f7;

// Brand Colors
$bwhite:                    #fff;
$bblack:                    #494949;

$bprimary:                  #0084ca;
$bprimary-light:            lighten($bprimary, 10%);
$bprimary-dark:             #12597f;
$bprimary-alt:              #1574a6;
$bprimary-accent:           #60b8e6;

$bhighlight:                $bprimary;

$bsuccess:             		#4eb510;
$bwarning:             		#edce41;
$bdanger:              		#df1b24;
$status-colors:             $bdanger, $bsuccess, $bwarning;

$gbase:                     #a8a8a8;
$gdark:                     #757575;
$gdarker:                   #494949;
$gdarkest:                  #3f3f3f;
$glight:                    #e7e7e7;
$glighter:                  #f2f2f2;
$glightest:                 #f7f7f7;

$gray:                      $gdarker;

$tc-link:                   $bhighlight;

$shadow:                    rgba(0, 0, 0, .3);
$shadow-light:              rgba(0, 0, 0, .2);

// Font declarations
$fw-hairline:           100;
$fw-light:              300;
$fw-regular:            400;
$fw-semibold:           600;
$fw-bold:               700;
$fw-extrabold:          800;

$fwh:					$fw-hairline;
$fwl:					$fw-light;
$fwr:					$fw-regular;
$fws:					$fw-semibold;
$fwb:					$fw-bold;
$fwe:					$fw-extrabold;

// Base Font
$tc-base:               $gdarkest;
$base-font-size:        16;
$base-font-sizepx:      16px;
$base-line-height:      1.5em;
$base-font-family:      'PT Sans', Helvetica, Arial, sans-serif;
$base-font-weight:      $fw-regular;
$bfaf:                  $base-font-family;

$icon-font-family:      'FontAwesome';
$ifaf:                  $icon-font-family;

// Fixed Font
$fixed-font-size:       85%;
$fixed-font-family:     monospace;
$fixed-line-height:     $base-line-height;

// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}

// Mediaqueries breakpoints
$screen-xs:         480px;
$screen-sm:         768px;
$screen-md:         992px;
$screen-lg:         1280px;

$screen-xs-min:     $screen-xs;
$screen-sm-min:     $screen-sm;
$screen-md-min:     $screen-md;
$screen-lg-min:     $screen-lg;

$screen-xs-max:     ($screen-sm-min - 1);
$screen-sm-max:     ($screen-md-min - 1);
$screen-md-max:     ($screen-lg-min - 1);
